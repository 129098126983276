import * as React from 'react';
import { Link } from 'gatsby';
import { translate } from 'react-i18next';
import * as helper from '../../helper/helpers';
interface IFeedBackProps {
    t?: any;
    i18n?: any;
}

interface IFeedBackState {
    success: string; 
    ContactName: string;
}

class FeedBackComponent extends React.Component<IFeedBackProps, IFeedBackState> {
    constructor(props: IFeedBackProps) {
        super(props);
        this.state = {
            success: '', // 0 = false, 1 = true
            ContactName: ''
        };
    }
    
    public componentDidMount() {
        const success = helper.findParamQueryString('success');
        const ContactName = helper.findParamQueryString('f');
        this.setState({ success, ContactName});
    }

    public getFeedBackTitle(ContactName: string) {
        switch(ContactName) {
            case 'CONTACT_CONSTRUCTION': return 'CONTACT_CONSTRUCTION_FEEDBACK_TITLE';
            case 'CONTACT_ME' : return 'CONTACT_ME_FEEDBACK_TITLE';
            default: return '';
        }
    }
    public getFeedBackMessage (ContactName: string, success: string) {
        switch(ContactName) {
            case 'CONTACT_DEMAND' : return success === '1'? 'CONTACT_DEMAND_FEEDBACK_SUCCESS' : 'CONTACT_DEMAND_FEEDBACK_ERROR';
            case 'CONTACT_ME' : return success === '1'? 'CONTACT_ME_FEEDBACK_SUCCESS' : 'CONTACT_ME_FEEDBACK_ERROR';
            case 'DEMAND_REGISTER' : return success === '1'? 'MAIL_THANK_TEXT' : 'MAIL_ERROR';
            case 'SEND_TO_FRIEND' : return success === '1'? 'SEND_TO_FRIEND_FEEDBACK_SUCCESS' : 'SEND_TO_FRIEND_FEEDBACK_ERROR';
            case 'SITE_CONTACT': return success === '1'? 'SITE_CONTACT_FEEDBACK_SUCCESS' : 'SITE_CONTACT_FEEDBACK_ERROR';
            default: return '';
        }
    }

    public getReturnURL() {
        if(typeof window !== 'undefined') {
            // get Url back
            const Url = window.sessionStorage.getItem('URL');
            return !!Url? Url : '/';
        } else {
            return '/';
        }
    }

    public render() {
        const { t } = this.props;
        return (
                <div>
                    {this.state.ContactName === 'DEMAND_REGISTER' && <Link to={this.getReturnURL()}>{t('DEMAND_FEEDBACK_RETURN')}</Link>}
                    <div className={this.state.success === '1'? 'success_title' : 'error_title'}><h3>{t(this.getFeedBackTitle(this.state.ContactName.toUpperCase()))}</h3></div>
                    <div className={this.state.success === '1'? 'success_message': 'error_message'}><h3>{t(this.getFeedBackMessage(this.state.ContactName.toUpperCase(),this.state.success))}</h3></div>
                </div>
        );
    }
}

export default translate('translations')(FeedBackComponent);
