import * as React from 'react';
import { translate } from 'react-i18next';
import Layout from '../components/GeneralComponent/layout';
import FeedBack from '../components/FormComponent/feedback';
import * as helper from '../helper/helpers';
interface IFeedBackProps {
    t?: any;
    i18n?: any;
}

class FeedBackPageComponent extends React.Component<IFeedBackProps> {
    constructor(props: IFeedBackProps) {
        super(props);
    }

    public render() {
        const { i18n } = this.props;
        helper.setLanguageDefault(i18n.language);
        return (
            <Layout>
                <div id='content-row'>
                    <div className='row-container'>
                        <div className='container-fluid'>
                            <div className='content-inner row-fluid'>
                                <FeedBack />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default translate('translations')(FeedBackPageComponent);
